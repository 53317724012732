import React, { useContext } from 'react'
import styled, { keyframes, ThemeContext } from 'styled-components'

import AU from '../assets/flags/au.svg'
import Int from '../assets/flags/world.svg'
import US from '../assets/flags/us.svg'
import Container from '../components/Container'
import Footer from '../components/Footer/Footer'

import { uid } from 'react-uid'
import { PageProps, Link } from 'gatsby'
import { Site } from '../components/Site'
import { Button } from '../components/Button'
import { SEO } from '../SEO'
import { Main } from '../components/Styled'
import { flatMap } from '../components/utils/flatMap'

enum Team {
  Engineering = 'Engineering',
  CustomerSuccess = 'Customer Success',
  Design = 'Design',
  Sales = 'Sales',
  DataScience = 'Data Science'
}

const jobs: { role: string; location: string; team: Team; flag: string; link: string; filled?: boolean }[] = [
  {
    role: 'Software Engineer — Features',
    location: 'Any',
    team: Team.Engineering,
    flag: Int,
    link: 'https://www.seek.com.au/job/51309035/',
    filled: true
  },
  {
    role: 'Software Engineer — Research',
    location: 'Any',
    team: Team.Engineering,
    flag: Int,
    link: 'https://www.seek.com.au/job/51299790/',
    filled: true
  },
  {
    role: 'Customer Engineer',
    location: 'Any',
    team: Team.Engineering,
    flag: Int,
    link: 'https://www.seek.com.au/job/51299733/',
    filled: true
  },
  {
    role: 'Head of Sales',
    location: 'Australia',
    team: Team.Sales,
    flag: AU,
    link: 'https://www.seek.com.au/job/50976088/',
    filled: true
  },
  {
    role: 'User Interface Designer',
    location: 'Australia',
    team: Team.Engineering,
    flag: AU,
    link: 'https://www.seek.com.au/job/50976088/',
    filled: true
  },
  {
    role: 'Platform Engineer',
    location: 'USA',
    team: Team.Engineering,
    flag: US,
    link: 'https://www.seek.com.au/job/50976087/',
    filled: true
  }
]

const Careers: React.FC<PageProps> = props => {
  const themeContext = useContext(ThemeContext)
  return (
    <Site seo={SEO.careers} {...props}>
      <Main>
        <Container padding={flatMap(['9rem 1.5rem 1.5rem'])}>
          <Introduction>
            <H1>Help us continue to be the global leader in AI-driven speech anlaytics</H1>
            <H2>
              {' '}
              We hire and retain diverse and fantastically talented people and are always looking for individuals who
              share our drive for excellence. Discover some of the open roles below
            </H2>
            <Link to='/about#values' style={{ margin: '0 auto' }}>
              <Button action='Learn about our values' btnHeight='auto' bgColor={themeContext.colors.green} />
            </Link>
          </Introduction>
        </Container>
        <Colored />
        <OpenRoles>
          <Heading>
            <Container>{jobs.length > 0 ? <h3>We&apos;re Hiring</h3> : <h3>Sorry</h3>}</Container>
          </Heading>
          <Container>
            <Job>
              {jobs.length > 0 && (
                <>
                  <Legend>
                    <p>Role</p>
                  </Legend>
                  <Legend>
                    <p>Location</p>
                  </Legend>
                  <Legend>
                    <p>Team</p>
                  </Legend>
                </>
              )}
            </Job>
          </Container>

          <OpenRolesNavigation>
            <OpenRolesContainer>
              {jobs.length > 0 ? (
                jobs.map(job => {
                  return (
                    <li key={uid(job)}>
                      <Job>
                        <Role>
                          {' '}
                          <p> {job.role}</p>{' '}
                        </Role>
                        <City>
                          <img src={job.flag} alt='flag' />
                          <p> {job.location}</p>{' '}
                        </City>
                        <TeamRow>
                          <p> {job.team}</p>{' '}
                        </TeamRow>
                        {job.link && (
                          <CTA filled={job.filled}>
                            <a href={job.filled ? undefined : job.link} onClick={e => job.filled && e.preventDefault()}>
                              <Button
                                action={job.filled ? 'filled' : 'apply'}
                                btnHeight='1.5rem'
                                secondary
                                bgColor='#fff'
                              />
                            </a>
                          </CTA>
                        )}
                      </Job>
                    </li>
                  )
                })
              ) : (
                <h4>There are no available positions at this time</h4>
              )}
            </OpenRolesContainer>
          </OpenRolesNavigation>
        </OpenRoles>
      </Main>
      <Footer />
    </Site>
  )
}

const H1 = styled.h1`
  line-height: 1.2;
  font-weight: 500;
  font-size: 3rem;
  letter-spacing: -1.5px;
  padding-bottom: 3rem;
  margin: 0;
  text-align: center;
  @media all and (max-width: 48em) {
    font-size: 2rem;
  }

  @media all and (max-width: 26.563em) {
    font-size: 1.5rem;
  }
`

const H2 = styled.h2`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey700};
  line-height: 1.6;
  font-size: 1.2rem;
  padding-bottom: 1.5rem;
  max-width: 50ch;
  text-align: center;
  margin: 0 auto;
  @media all and (max-width: 48em) {
    font-size: 1rem;
  }

  @media all and (max-width: 26.563em) {
    font-size: 0.875rem;
  }
`

const TeamRow = styled.span`
  color: ${({ theme }) => theme.colors.grey700};
`

const AnimatedGradient = keyframes`
    0%{background-position:46% 0%}
    50%{background-position:55% 100%}
    100%{background-position:46% 0%}
`

const Colored = styled.div`
  height: 24px;
  background: linear-gradient(174deg, #6931d4, #1ed760);
  background-size: 400% 400%;
  animation: ${AnimatedGradient} 30s ease infinite;
  margin: 3rem 0;
`

const Introduction = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9rem 1.5rem 0;

  @media all and (max-width: 26.563em) {
    padding: 0 0 0 1.5rem;
  }
`

const OpenRoles = styled.section`
  min-height: 50vh;
  padding: 1.5rem 0;

  h3 {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -1px;
    padding: 0 1.5rem;
  }
`

const OpenRolesNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`

const OpenRolesContainer = styled.ul`
  width: 100%;
  & > :nth-of-type(2n) {
    background: ${({ theme }) => theme.colors.grey200};
  }
`

const Role = styled.span`
  font-weight: 500;
`

const City = styled.span`
  display: grid;
  color: ${({ theme }) => theme.colors.grey700};
  gap: 0.75rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;

  @media all and (max-width: 34.375em) {
    grid-row: 2/2;
  }
`

const Heading = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  padding-bottom: 3rem;
`

const Job = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 1.5rem;
  max-width: ${({ theme }) => theme.container.maxWidth};
  margin: auto;

  @media all and (max-width: 48em) {
    grid-template-columns: 2fr 1fr 1fr;
    & > :nth-child(3) {
      display: none;
    }

    p {
      font-size: 0.875rem;
    }
  }

  @media all and (max-width: 34.375em) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;

    & > :nth-child(2):not(${City}) {
      display: none;
    }
  }
`

interface CTAI {
  filled?: boolean
}

const CTA = styled.span<CTAI>`
  justify-self: end;

  button {
    border: ${({ filled, theme }) => filled && `1px solid ${theme.colors.grey500}`};
    background: ${({ filled, theme }) => filled && theme.colors.grey500};
    color: ${({ filled }) => filled && '#fff'};
  }
`

const Legend = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey500};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
`

export default Careers
